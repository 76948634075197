import { gql } from '@apollo/client';

export interface ProductNamesByCategoryQueryResponse {
  loading: boolean;
  error: any;
  allProductCategories: {
    nodes: ProductCategoryNode[];
    totalCount: number;
  };
}

export interface ProductCategoryNode {
  productsByCategoryId: {
    edges: {
      node: ProductNode;
    }[];
  };
}

export interface ProductNode {
  id: number;
  name: string;
  productReviewsByProductId: {
    totalCount: number;
  };
  producerByProducerId: {
    name: string;
  };
}

export default gql`
  query ProductNamesByCategoryQuery($categoryName: String!) {
    allProductCategories(condition: { name: $categoryName }) {
      totalCount
      nodes {
        productsByCategoryId {
          edges {
            node {
              id
              name
              productReviewsByProductId {
                totalCount
              }
              producerByProducerId {
                name
              }
            }
          }
        }
      }
    }
  }
`;
