import { PRODUCT_EDIT_OPEN } from 'constants/googleAnalytics/actions';
import { CAT_PRODUCT } from 'constants/googleAnalytics/categories';

import * as React from 'react';

import {
  KeyboardBackspace as KeyboardBackspaceIcon,
  CheckCircleOutline,
  CloseOutlined,
} from '@mui/icons-material';
import { Paper, IconButton } from '@mui/material';
import FormSectionHeader from 'components/FormSectionHeader';
import MaterialButton from 'components/MaterialButton';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './ProductCard.module.css';
import RenderAttributes from './RenderAttributes';
import RenderNutritionalInfo from './RenderNutritionalInfo';
import RenderProductCategory from './RenderProductCategory';
import RenderProductComponentBase from './RenderProductComponentBase';
import RenderProductComponentOther from './RenderProductComponentOther';
import RenderProductCountry from './RenderProductCountry';
import RenderProductFeature from './RenderProductFeature';
import RenderProductFolder from './RenderProductFolder';
import RenderProductInfo from './RenderProductInfo';
import RenderProductText from './RenderProductText';
import RenderProductThumbnail from './RenderProductThumbnail';
import { PageHeader } from '../../../styles/themeComponents';
import { getMainImageUrl } from '../helper';

const { event } = ReactGA;

interface Props {
  name: string;
  localName?: string;
  defaultAttributes?: object;
  ingredients?: string;
  dietaryRestrictions: string;
  restrictions: string;
  country?: string;
  countryOfPurchase?: string;
  brand: string;
  servingVessel?: string;
  physicalState?: string;
  nutritionalInformation?: object;
  productAttributes?: object;
  productTags?: any[];
  oldProductClass?: string;
  productClasses?: object[];
  productImages?: object;
  nutritionalInfoImages?: string;
  isPublic?: boolean;
  prototype?: boolean;
  aroma?: boolean;
  id?: number;
  folder?: object;
  folderId?: number;
  folderResults?: object;
  toggleEdit: (event) => void;
  locationState: string;
  productFeatures: any;
  productCategory: any[];
  productComponentBases: any;
  productComponentOthers: any[];
  panelProduct: any[];
}

const ProductCard = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [addingFolder, setAddingFolder] = React.useState(false);

  const images = []
    .concat(props.productImages && props.productImages)
    .concat(props.nutritionalInfoImages && props.nutritionalInfoImages);

  return (
    <Paper className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <div className={styles.headerTextContainer}>
            <IconButton
              onClick={() => history.goBack()}
              size="small"
              style={{ marginLeft: -26 }}
            >
              <KeyboardBackspaceIcon fontSize="small" />
              <PageHeader>{t('product.products')}</PageHeader>
            </IconButton>
          </div>
          <h3 className={styles.productFieldsTitle}>{props.name}</h3>
        </div>
      </div>
      <div className={styles.imageContainer}>
        {images.length > 0 ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <img
              className={styles.productImage}
              src={getMainImageUrl(images)}
              alt="main-image"
            />
            <RenderProductThumbnail
              imageUrls={images.map((image) => image.url)}
            />
          </div>
        ) : (
          <div />
        )}
        <div className={styles.headerActionContainer}>
          <RenderProductFolder
            folderId={props.folderId}
            folder={props.folder}
            addingFolder={addingFolder}
            setAddingFolder={setAddingFolder}
            productId={props.id}
          />
          <MaterialButton
            onClick={(e) => {
              props.toggleEdit(e);
              event({
                category: CAT_PRODUCT,
                action: PRODUCT_EDIT_OPEN,
                label: props.id.toString(),
              });
            }}
            variant="outlined"
            soft
            teal
            style={{ width: 140, marginTop: 20 }}
          >
            {t('product.editProductHeader')}
          </MaterialButton>
        </div>
      </div>
      <div className={styles.productInfoTable}>
        <table>
          <tbody>
            <tr>
              <td>
                <div className={styles.infoContainer}>
                  <FormSectionHeader text={t('product.productName')} />
                  {props.name ? (
                    <span className={styles.infoContent}>
                      {props.name}
                      {props.localName && ` (${props.localName})`}
                    </span>
                  ) : (
                    <span className={styles.requiredField}>
                      {t('product.required')}
                    </span>
                  )}
                </div>
              </td>
              <td>
                <div className={styles.infoContainer}>
                  <FormSectionHeader text={t('product.productBrand')} />
                  {props.brand ? (
                    <span className={styles.infoContent}>{props.brand}</span>
                  ) : (
                    <span className={styles.requiredField}>
                      {t('product.required')}
                    </span>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className={styles.infoContainer}>
                  <FormSectionHeader text={t('product.attributes')} />
                  <RenderAttributes
                    defaultAttributes={props.defaultAttributes}
                    productAttributes={props.productAttributes}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={styles.infoContainer}>
                  <FormSectionHeader text={t('product.productCategory')} />
                  {props.productCategory ? (
                    <RenderProductCategory
                      productCategory={props.productCategory}
                    />
                  ) : (
                    <span className={styles.requiredField}>
                      {t('product.required')}
                    </span>
                  )}
                </div>
              </td>
              <td>
                <div className={styles.infoContainer}>
                  <FormSectionHeader text={t('product.productFeature')} />
                  {props.productFeatures != '' ? (
                    <RenderProductFeature
                      productFeatures={props.productFeatures}
                    />
                  ) : (
                    <span className={styles.requiredField}>
                      {t('product.required')}
                    </span>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={styles.infoContainer}>
                  <FormSectionHeader text={t('product.productComponentBase')} />
                  {props.productComponentBases != '' ? (
                    <RenderProductComponentBase
                      productComponentBases={props.productComponentBases}
                    />
                  ) : (
                    <span className={styles.requiredField}>
                      {t('product.required')}
                    </span>
                  )}
                </div>
              </td>
              <td>
                <div className={styles.infoContainer}>
                  <FormSectionHeader
                    text={t('product.productComponentOther')}
                  />
                  <RenderProductComponentOther
                    productComponentOthers={props.productComponentOthers}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className={styles.infoContainer}>
                  <FormSectionHeader text={t('product.ingredients')} />
                  <RenderProductText property={props.ingredients} />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className={styles.infoContainer}>
                  <FormSectionHeader text={t('product.allergenInfo')} />
                  {props.dietaryRestrictions ? (
                    <RenderProductInfo
                      value={props.dietaryRestrictions}
                      property="allergenInfo"
                    />
                  ) : (
                    <span className={styles.requiredField}>
                      {t('product.required')}
                    </span>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className={styles.infoContainer}>
                  <FormSectionHeader text={t('product.dietaryRestrictions')} />
                  {props.restrictions ? (
                    <RenderProductInfo
                      value={props.restrictions}
                      property="restrictions"
                    />
                  ) : (
                    <span className={styles.requiredField}>
                      {t('product.required')}
                    </span>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className={styles.infoContainer}>
                  <FormSectionHeader text={t('product.nutritionalInfo')} />
                  <RenderNutritionalInfo
                    nutritionalInfo={props.nutritionalInformation}
                  />
                </div>
              </td>
            </tr>
            <tr>
              {/* <td>
                  <div className={styles.infoContainer}>
                    <FormSectionHeader text={t('product.servingVessel')} />
                    <RenderProductInfo
                      property="servingVessel"
                      value={servingVessel}
                    />
                  </div>
                </td> */}
              <td colSpan={2}>
                <div className={styles.infoContainer}>
                  <FormSectionHeader text={t('product.physicalState')} />
                  {props.physicalState ? (
                    <RenderProductInfo
                      property="physicalState"
                      value={props.physicalState}
                    />
                  ) : (
                    <span className={styles.requiredField}>
                      {t('product.required')}
                    </span>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={styles.infoContainer}>
                  <FormSectionHeader text={t('product.countryOfOrigin')} />
                  {props.country ? (
                    <RenderProductCountry countryCode={props.country} />
                  ) : (
                    <span className={styles.requiredField}>
                      {t('product.required')}
                    </span>
                  )}
                </div>
              </td>
              <td>
                <div className={styles.infoContainer}>
                  <FormSectionHeader text={t('product.countryOfPurchase')} />
                  {props.countryOfPurchase ? (
                    <RenderProductCountry
                      countryCode={props.countryOfPurchase}
                    />
                  ) : (
                    <span className={styles.requiredField}>
                      {t('product.required')}
                    </span>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={styles.infoContainerSmall}>
                  <FormSectionHeader text={t('product.publicProduct')} />
                  <span className={styles.infoContent}>
                    {props.isPublic ? (
                      <CheckCircleOutline color="secondary" />
                    ) : (
                      <CloseOutlined color="error" />
                    )}
                  </span>
                </div>
              </td>
              <td>
                <div className={styles.infoContainerSmall}>
                  <FormSectionHeader text={t('product.prototypeProduct')} />
                  {props.prototype === null ? (
                    <span className={styles.requiredField}>
                      {t('product.required')}
                    </span>
                  ) : (
                    <span className={styles.infoContent}>
                      {props.prototype ? (
                        <CheckCircleOutline color="secondary" />
                      ) : (
                        <CloseOutlined color="error" />
                      )}
                    </span>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className={styles.infoContainerSmall}>
                  <FormSectionHeader text={t('product.aroma')} />
                  <span className={styles.infoContent}>
                    {props.aroma ? (
                      <CheckCircleOutline color="secondary" />
                    ) : (
                      <CloseOutlined color="error" />
                    )}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table className={styles.dateTable}>
          <thead className={styles.dateTableHead}>
            <tr>
              <th>Production Date</th>
              <th>Expiration Date</th>
              <th>Panel</th>
            </tr>
          </thead>
          <tbody className={styles.dateTableBody}>
            {props.panelProduct.length > 0 ? (
              props.panelProduct.map((item) => (
                <tr key={item.panel.pin}>
                  <td>{item.expirationDate || 'No data available'}</td>
                  <td>{item.expirationDate || 'No data available'}</td>
                  <td>{item.panel.pin}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>No Data Available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Paper>
  );
};

export default ProductCard;
