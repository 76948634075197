import { getCountryWithEmoji } from 'constants/country';

import * as React from 'react';

import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Optional } from 'typescript-optional';

import {
  getAges,
  getFemaleFrac,
  getMaleFrac,
  getSansRejector,
  getReportCategoryInfo,
  getReportParam,
} from './utils';

interface Props {
  data: reports.ReportJobResults;
  isArchetypeReport: boolean;
}

const ReportDemographics: React.FC<Props> = (props) => {
  const { data, isArchetypeReport } = props;

  const { t } = useTranslation();

  const getData = (data: reports.ReportJobResults): reports.ReportInfoDetail =>
    data.report;

  if (!data) {
    return <div />;
  }

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>{t('reportsQa.country')}</TableCell>
          <TableCell>
            {getCountryWithEmoji(
              getReportParam<string | string[]>(
                Optional.of(getData(data).params),
                'countries',
              ),
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('reportsQa.ages')}</TableCell>
          <TableCell>{getAges(getData(data))}</TableCell>
        </TableRow>
        {!isArchetypeReport && (
          <TableRow>
            <TableCell>{t('reportsQa.gender')}</TableCell>
            <TableCell>
              {getMaleFrac(getData(data)) +
                '% ' +
                t('reportsQa.male') +
                ' / ' +
                getFemaleFrac(getData(data)) +
                '% ' +
                t('reportsQa.female')}
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>{t('reportsQa.socioEcon')}</TableCell>
          <TableCell>Gen-Pop</TableCell>
        </TableRow>
        {getSansRejector(getData(data)) && (
          <TableRow>
            <TableCell>{t('reportsQa.sansRejector')}</TableCell>
            <TableCell>Yes</TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>{t('reportsQa.reportCategory')}</TableCell>
          <TableCell>{getReportCategoryInfo(getData(data))}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ReportDemographics;
