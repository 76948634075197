import * as React from 'react';
import { useState } from 'react';

import { CheckCircleRounded, ErrorRounded } from '@mui/icons-material';
import { useTheme, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Field } from 'redux-form';

import syncValidate from './syncValidate';
import useStyles from './useStyles';
import sessionSet from '../../actions/sessionSet';
import FieldTextInput from '../../components/FieldTextInput';
import FormFieldSet from '../../components/FormFieldSet';
import MaterialButton from '../../components/MaterialButton';
import { AUTHENTICATION_FORM } from '../../constants/formNames';
import FormContainer from '../Form';

const AuthenticationContainer: React.FC = () => {
  const classes = useStyles(useTheme());
  const dispatch = useDispatch();
  const welcomeMessage =
    'Welcome to ' +
    (window.location.href.includes('gastrograph.com')
      ? 'Gastrograph'
      : 'SensoryLink');
  const failedLogin = useSelector(
    (state) => (state as any).session && (state as any).session.failedLogin,
  );
  const failedLoginEmail = useSelector(
    (state) =>
      (state as any).session && (state as any).session.failedLoginEmail,
  );
  const [resetSubmitResult, setResetSubmitResult] = useState<{ ok: boolean }>(
    null,
  );
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [forgotEmail, setForgotEmail] = useState<string>('');
  const [emailError, setEmailError] = useState('');

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email) {
      return 'Email is required';
    }
    if (!emailPattern.test(email)) {
      return 'Invalid email address';
    }
    return '';
  };

  const handleEmailChange = (e) => {
    setEmailError('');
    setResetSubmitResult(null);
    const email = e.target.value;
    setForgotEmail(email);
    const error = validateEmail(email);
    if (error) setEmailError(error);
  };

  const resetFailedLogin = () => {
    dispatch(
      sessionSet({
        failedLogin: false,
        failedLoginEmail: null,
      }),
    );
  };

  return (
    <div className={classes.authenticationContainer}>
      {forgotPassword ? (
        <div className={classes.welcomePanel}>
          <div className={classes.header}>
            <span>{welcomeMessage}</span>
          </div>
          <form className={classes.authenticationForm}>
            <div>
              <FieldTextInput
                fullWidth
                margin="none"
                variant="outlined"
                key="email"
                label="Reset Email"
                input={{
                  type: 'email',
                  value: forgotEmail,
                  onChange: handleEmailChange,
                }}
                placeholder="Enter email for password reset"
                meta={{
                  error: !!emailError.length,
                }}
              />
            </div>
            <div className={classes.forgotPasswordStyle}>
              <Box
                component="div"
                onClick={() => {
                  setForgotPassword(false);
                  setEmailError('');
                  setResetSubmitResult(null);
                  resetFailedLogin();
                }}
                sx={{
                  color: 'primary.main',
                  cursor: 'pointer',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Sign In
              </Box>
            </div>
            <div>
              <div
                className={classes.panelFooter}
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <MaterialButton
                  variant="outlined"
                  onClick={() => {
                    setResetSubmitResult(null);
                    setEmailError('');
                    fetch('/iam/requestPasswordResetEmail', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({ email: forgotEmail }),
                    })
                      .then(async (result) => {
                        const resetPasswordResponse = await result.text();
                        if (resetPasswordResponse == 'Email not found!') {
                          setEmailError('The email provided does not exist');
                          return setResetSubmitResult({ ok: false });
                        }
                        setResetSubmitResult(result);
                      })
                      .catch((error) => setResetSubmitResult(error));
                  }}
                  soft
                  disabled={!forgotEmail.length || !!emailError.length}
                >
                  Reset Password
                </MaterialButton>
                {!!resetSubmitResult && (
                  <div
                    style={{
                      color: resetSubmitResult.ok ? 'green' : 'red',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {resetSubmitResult.ok ? (
                      <CheckCircleRounded fontSize="small" htmlColor="green" />
                    ) : (
                      <ErrorRounded fontSize="small" htmlColor="red" />
                    )}
                    &nbsp;
                    {resetSubmitResult.ok
                      ? 'Reset email sent'
                      : (emailError ?? 'An error occurred')}
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <FormContainer
          formConfiguration={{ validate: syncValidate }}
          formName={AUTHENTICATION_FORM}
          render={({ handleSubmit, invalid, submitting }) => (
            <div className={classes.welcomePanel}>
              <div className={classes.header}>
                <span>{welcomeMessage}</span>
              </div>
              <form
                className={classes.authenticationForm}
                onSubmit={handleSubmit}
              >
                {submitting && <p>Signing In...</p>}
                <FormFieldSet
                  renderInputs={({ inputClassName }) => [
                    <Field
                      component={FieldTextInput}
                      className={inputClassName}
                      fullWidth
                      margin="none"
                      variant="outlined"
                      key="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      placeholder="you@example.com"
                      style={{ marginBottom: 20 }}
                    />,
                    <Field
                      component={FieldTextInput}
                      className={inputClassName}
                      fullWidth
                      variant="outlined"
                      margin="none"
                      key="password"
                      label="Password"
                      name="password"
                      placeholder="Password"
                      autoComplete="current-password"
                      type="password"
                    />,
                  ]}
                />
                <div className={classes.forgotPasswordStyle}>
                  <Box
                    component="div"
                    onClick={() => {
                      setForgotPassword(true);
                      setForgotEmail('');
                      setResetSubmitResult(null);
                      resetFailedLogin();
                    }}
                    sx={{
                      color: 'primary.main',
                      cursor: 'pointer',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    Forgot Password?
                  </Box>
                </div>
                <div className={classes.panelFooter}>
                  <MaterialButton
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                    disabled={invalid}
                    type="submit"
                  >
                    Sign In
                  </MaterialButton>
                </div>
                {failedLogin && (
                  <div>
                    <div
                      className={classes.panelFooter}
                      style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <MaterialButton
                        variant="outlined"
                        onClick={() => {
                          setEmailError('');
                          setResetSubmitResult(null);
                          fetch('/iam/requestPasswordResetEmail', {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ email: failedLoginEmail }),
                          })
                            .then(async (result) => {
                              const resetPasswordResponse = await result.text();
                              if (resetPasswordResponse == 'Email not found!') {
                                setEmailError(
                                  'The Email provided does not exist',
                                );
                                return setResetSubmitResult({ ok: false });
                              }
                              setResetSubmitResult(result);
                            })
                            .catch((error) => setResetSubmitResult(error));
                        }}
                        soft
                      >
                        Reset Password
                      </MaterialButton>
                      {!!resetSubmitResult && (
                        <div
                          style={{
                            color: resetSubmitResult.ok ? 'green' : 'red',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {resetSubmitResult.ok ? (
                            <CheckCircleRounded
                              fontSize="small"
                              htmlColor="green"
                            />
                          ) : (
                            <ErrorRounded fontSize="small" htmlColor="red" />
                          )}
                          &nbsp;
                          {resetSubmitResult.ok
                            ? 'Reset email sent'
                            : (emailError ?? 'An error occurred')}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </form>
            </div>
          )}
        />
      )}
    </div>
  );
};

AuthenticationContainer.displayName = 'AuthenticationContainer';

export default AuthenticationContainer;
