import { OPTIMIZATION, COMPUTATIONAL_CREATIVITY } from 'constants/report';

import * as React from 'react';

import { useQuery } from '@apollo/client';
import OrderOfOperationsCountQuery from '@graphql/queries/OrderOfOperationsCountQuery';
import { Grid, Box } from '@mui/material';
import ActionSummary from 'components/ExecutiveSummary/ActionSummary';
import CategoryNorms from 'components/ExecutiveSummary/CategoryNorms';
import OptimizationTop3Steps from 'components/ExecutiveSummary/OptimizationTop3Steps';
import PositiveNegativeDrivers from 'components/ExecutiveSummary/PositiveNegativeDrivers';
import StackRankPqRange from 'components/ExecutiveSummary/StackRankPqRange';
import TextureExecutiveSummary from 'components/ExecutiveSummary/TextureExecutiveSummary';
import LoadingScreen from 'components/LoadingScreen';
import FlavorSummaryAttributeIntensity from 'components/Report/FlavorSummaryAttribute';
import StackRankDriversTable from 'components/Report/StackRankComparison';
import {
  showStackRankDrivers,
  showTopThreeOpt,
  useCustomerPreferences,
} from 'services/customerPreferences';
import { nvlReportId } from 'utils/afsUtils';

interface Props {
  projectId: number;
  projectReports: reports.ProjectReportSummary[];
  selectedReportId: string;
}

const ProjectExecutiveSummary: React.FC<Props> = (props: Props) => {
  const { selectedReportId, projectReports, projectId } = props;
  const [canShowTexture, setCanShowTexture] = React.useState<boolean>(true);

  const customerPreferences = useCustomerPreferences();

  const isOptimizationReport = (
    projectReports: reports.ProjectReportSummary[],
    selectedReportId: string,
  ): boolean => {
    return (
      projectReports.find((i) => i.reportId === selectedReportId).reportType ===
      OPTIMIZATION
    );
  };

  const isCompCreativityReport = (
    projectReports: reports.ProjectReportSummary[],
    selectedReportId: string,
  ): boolean => {
    return (
      projectReports.find((i) => i.reportId === selectedReportId).reportType ===
      COMPUTATIONAL_CREATIVITY
    );
  };

  const { data: optSummaryCnt, loading: loadingOptSummaryCnt } =
    useQuery<reports.ReportOrderOfOperationsCountResponse>(
      OrderOfOperationsCountQuery,
      {
        variables: {
          reportID: nvlReportId(selectedReportId),
        },
      },
    );

  if (loadingOptSummaryCnt) {
    return <LoadingScreen />;
  }

  const items = [
    showTopThreeOpt(customerPreferences) &&
      isOptimizationReport(projectReports, selectedReportId) &&
      optSummaryCnt &&
      (optSummaryCnt?.allRpOptSteps?.totalCount ?? 0) > 0 && (
        <Box key="top3">
          <OptimizationTop3Steps
            projectId={projectId}
            reportId={selectedReportId}
          />
        </Box>
      ),
    !isOptimizationReport(projectReports, selectedReportId) && (
      <Box key="drivers">
        <PositiveNegativeDrivers
          projectId={projectId}
          reportId={selectedReportId}
        />
      </Box>
    ),
    <Box key="stackrank">
      <StackRankPqRange projectId={projectId} reportId={selectedReportId} />
    </Box>,
    isOptimizationReport(projectReports, selectedReportId) && (
      <Box key="action">
        <ActionSummary projectId={projectId} reportId={selectedReportId} />
      </Box>
    ),
    !isOptimizationReport(projectReports, selectedReportId) &&
    canShowTexture && (
      <Box key="texture">
        <TextureExecutiveSummary
          projectId={projectId}
          reportId={selectedReportId}
          setCanShowTexture={setCanShowTexture}
        />
      </Box>
    ),
    <Box key="category">
      <CategoryNorms projectId={projectId} reportId={selectedReportId} />
    </Box>,
  ];

  const validItems = items.filter(Boolean);
  const leftColumnItems = validItems.filter((_, i) => i % 2 === 0);
  const rightColumnItems = validItems.filter((_, i) => i % 2 === 1);

  return (
    selectedReportId && (
      <Grid
        container
        spacing={2}
        style={{ marginTop: 50 }}
        key={selectedReportId}
      >
        <Grid item xs={12} sm={6}>
          <Box display="flex" flexDirection="column" gap={2}>
            {leftColumnItems}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box display="flex" flexDirection="column" gap={2}>
            {rightColumnItems}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <FlavorSummaryAttributeIntensity
            projectId={projectId}
            reportId={selectedReportId}
          />
        </Grid>

        {!isOptimizationReport(projectReports, selectedReportId) &&
          !isCompCreativityReport(projectReports, selectedReportId) &&
          showStackRankDrivers(customerPreferences) && (
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <StackRankDriversTable
                projectId={projectId}
                reportId={selectedReportId}
              />
            </Grid>
          )}
      </Grid>
    )
  );
};

export default ProjectExecutiveSummary;
