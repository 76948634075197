import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Search as SearchIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Input, InputAdornment, Modal } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import { ProductVersionSet } from 'components/Report/ProductVersion';
import { get, union, uniqBy } from 'lodash';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import CompetitiveProduct from './CompetitiveProduct';
import ProductPasteModal from './ProductPasteModal';
import AllProductSearch from '../../../components/AllProductSearch';
import FieldCheckBox from '../../../components/FieldCheckBox';
import LoadingScreen from '../../../components/LoadingScreen';
import MaterialButton from '../../../components/MaterialButton';
import PartnerAdminProductSearch from '../../../components/PartnerAdminProductSearch';
import { MARKET_SURVEY } from '../../../constants/report';
import {
  MultiProductSearchQueryType,
  ProductSearchQueryProduct,
  MultiProductIdSearchQuery,
} from '../../../graphql/queries/MultiProductSearchQuery';
import selectTokenRoles from '../../../selectors/sessionTokenRoles';
import { PageHeader, PageTitle } from '../../../styles/themeComponents';
import { defaultAllowedWorkspace } from '../../../utils/conchUtils';
import {
  hasPartnerAdminRole,
  hasSuperAdminRole,
} from '../../../utils/roleUtils';
import { WorkspaceOptions } from '../ReportType/WorkspaceOptionsSuperAdmin';
import styles from '../RequestReport.module.css';

export interface MarketSurveyProps {
  reportWorkspace: WorkspaceOptions;
  templateReportProductSet: ProductVersionSet;
  competitive_set_rich: any[];
  client_allowed_workspaces: string[];
  custom_class_db_rich: Partial<FormattedProduct>[];
  change: (field: string, value: any) => void;
  displayMode: boolean;
  producerId: number;
  competitive_set_folders: string[];
  report_type: SelectItem;
}

const MarketSurvey: React.FC<MarketSurveyProps> = ({
  reportWorkspace,
  templateReportProductSet,
  competitive_set_rich,
  custom_class_db_rich,
  client_allowed_workspaces,
  change,
  displayMode,
  producerId,
  competitive_set_folders,
  report_type,
}) => {
  const { t } = useTranslation();

  const [searchString, setSearchString] = useState<string>('');
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [showErrorModal, setErrorModal] = useState<boolean>(false);
  const [showProductModal, setProductModal] = useState<boolean>(false);
  const [showCustomClassDBModal, setCustomClassDBModal] =
    useState<boolean>(false);
  const [customClassDBLocal, setCustomClassDBLocal] =
    useState<FormattedProduct[]>(null);
  const [newProducts, setNewProduct] = useState<any[]>(competitive_set_rich);
  const [specialInterestProducts, setSpecialInterestProducts] = useState<
    string[]
  >([]);
  const [templateProductSet, setTemplateProductSet] =
    useState<ProductVersionSet>(templateReportProductSet);
  const [templateProductNamesLoaded, setTemplateProductNamesLoaded] =
    useState<boolean>(false);

  const userRoles = useSelector((state) => selectTokenRoles(state));
  const isPartnerAdmin = hasPartnerAdminRole(userRoles);
  const isSuperAdmin = hasSuperAdminRole(userRoles);

  const customClassDBCategoryWarn: () => string[] = () =>
    customClassDBLocal
      ? Array.from(
          new Set(
            customClassDBLocal
              .filter((p) => !!p.category)
              .map((p) => p.category.name)
              .filter((c) => !competitive_set_folders.includes(c)),
          ),
        )
      : [];

  useEffect(() => {
    if (!isEqual(templateProductSet, templateReportProductSet)) {
      setTemplateProductSet(templateReportProductSet);
    }
  }, [templateReportProductSet, templateProductSet]);

  useEffect(() => {
    if (!displayMode) {
      change(
        'client_allowed_workspaces',
        Array.from(
          new Set(
            union(
              client_allowed_workspaces,
              competitive_set_rich
                .map((p) => p.workspace)
                .filter((w) =>
                  defaultAllowedWorkspace(w, reportWorkspace.label),
                ),
            ),
          ),
        ),
      );
      change(
        'competitive_set_folders',
        competitive_set_rich
          .filter((p) => !!p.category)
          .map((p) => p.category.name),
      );
    }
  }, [competitive_set_rich]);

  const handleNameChange = (name, val) => {
    const updatedProducts = [...newProducts];
    updatedProducts
      .filter((prod) => prod.name === name)
      .map((p) => ({ ...p, rename: val }));
    change('competitive_set_rich', updatedProducts);
    setNewProduct(updatedProducts);
  };

  const updateProductsOfInterest = (name: string, checked: boolean) => {
    if (checked) {
      const updatedSpecialInterests = union(specialInterestProducts, [
        newProducts.filter((prod) => prod.name === name)[0].name,
      ]);
      // do I really need state?
      setSpecialInterestProducts(updatedSpecialInterests);
      change('products_of_special_interest', updatedSpecialInterests);
    } else {
      const updatedSpecialInterests = specialInterestProducts.filter(
        (prodName) => prodName !== name,
      );
      setSpecialInterestProducts(updatedSpecialInterests);
      change('products_of_special_interest', updatedSpecialInterests);
    }
  };

  const addProducts = (newProducts: FormattedProduct[]) => {
    change('competitive_set_rich', union(competitive_set_rich, newProducts));
    setNewProduct([...newProducts]);
    setProductModal(false);
  };

  const setCustomClassDB = (
    customClassDBProducts: FormattedProduct[],
    name: string,
  ) => {
    change('custom_class_db_rich', customClassDBProducts);
    change('custom_class_db_name', name);
    setCustomClassDBLocal(customClassDBProducts);
    setCustomClassDBModal(false);
  };

  const multiSelectProduct = (multipleProdcts, allProducts) => {
    if (newProducts.filter((p) => p.reviews <= 5).length > 0) {
      setErrorModal(true);
    } else {
      const names = multipleProdcts.map((p) => p.name);
      const allworkspaceProducts = allProducts
        .filter((product) => names.includes(product.name))
        .map((product) => ({
          ...product,
          workspace: product.producer.name,
        }));

      change(
        'competitive_set_rich',
        union(competitive_set_rich, allworkspaceProducts),
      );
      setNewProduct([...newProducts, ...multipleProdcts]);
      setShowSearch(false);
      setSearchString('');
    }
  };

  const addProduct = (newProduct, allProducts) => {
    if (newProduct.reviews > 5) {
      // Match by name for products in different workspaces
      const allworkspaceProducts = allProducts
        .filter((product) => product.name == newProduct.name)
        .map((product) => ({
          ...product,
          workspace: product.producer.name,
        }));

      change(
        'competitive_set_rich',
        union(competitive_set_rich, allworkspaceProducts),
      );
      setNewProduct([...newProducts, { ...newProduct }]);
      setShowSearch(false);
      setSearchString('');
    } else {
      setErrorModal(true);
    }
  };

  const deleteProduct = (productName) => {
    setNewProduct(
      [...newProducts].filter((product) => product.name !== productName),
    );
    change(
      'competitive_set_rich',
      competitive_set_rich.filter((product) => product.name !== productName),
    );
    showWarning && setShowWarning(false);
  };

  const handleChange = (string) => {
    setSearchString(string);
    setShowSearch(true);
  };

  const formatProducts = (
    products: ProductSearchQueryProduct[],
  ): FormattedProduct[] => {
    return products.map((product) => ({
      // duplicates getProductData function
      id: product.id,
      image:
        get(product, 'productImages.totalCount') > 0 &&
        product.productImages.nodes[0].url,
      name: product.name,
      brand: product.brand,
      date: moment(product.createdAt).format('ll'),
      reviews: get(product, 'productReviews.totalCount'),
      lastReview:
        get(product, 'productReviews.nodes.length') > 0
          ? moment(product.productReviews.nodes[0].createdAt).format('ll')
          : '',
      category: get(product, 'category'),
      workspace: product.producer.name,
      productWorkspaceId: product.producer.id,
      productWorkspaceName: product.producer.name,
    }));
  };

  const columns: TableColumn<FormattedProduct>[] = [
    {
      selector: (row: FormattedProduct) => row.id,
      name: 'Product ID',
      sortable: true,
      omit: true,
    },
    {
      selector: (row: FormattedProduct) => row.brand,
      name: t('product.productBrand'),
      sortable: true,
      width: '250px',
    },
    {
      selector: (row: FormattedProduct) => row.date,
      name: t('general.dateCreated'),
      sortable: true,
    },
    {
      selector: (row: FormattedProduct) => row.reviews,
      name: t('reviews.reviews'),
      width: '80px',
    },
    {
      selector: (row: FormattedProduct) => row.workspace,
      name: 'Workspace',
    },
    {
      selector: (row: FormattedProduct) => row.lastReview,
      name: t('reviews.lastReview'),
    },
    {
      selector: (row: FormattedProduct) => row.lastReview,
      name: t('reviews.lastReview'),
    },
  ];

  const [searchProductIds] = useLazyQuery<MultiProductSearchQueryType>(
    MultiProductIdSearchQuery,
  );

  useEffect(() => {
    if (templateReportProductSet) {
      searchProductIds({
        variables: {
          ids: templateProductSet?.getItems()?.map((d) => d.productId),
        },
        onCompleted: (data) => {
          const templateProducts = formatProducts(
            data?.allProducts?.nodes ?? [],
          );
          if (templateProducts?.length < 1) return;
          addProducts(templateProducts);
          change(
            'client_allowed_workspaces',
            Array.from(
              new Set(templateProducts?.map((product) => product.workspace)),
            ),
          );
          change(
            'competitive_set_folders',
            Array.from(
              new Set(
                templateProducts
                  ?.filter((p) => !!p.category)
                  ?.map((product) => product.category?.name),
              ),
            ),
          );
          setTemplateProductNamesLoaded(true);
        },
      });
    }
  }, [templateProductSet]);

  if (templateReportProductSet && !templateProductNamesLoaded) {
    return <LoadingScreen />;
  }

  return (
    <div>
      {!displayMode && (
        <div>
          <PageHeader>{t('navigation.reports')}</PageHeader>
          <PageTitle>
            {`${t('reports.createReportRequest')}: ${
              report_type.value === MARKET_SURVEY
                ? t('reports.marketSurvey')
                : t('reports.productReport')
            }`}
          </PageTitle>

          <Modal
            open={showErrorModal}
            onClose={() => setErrorModal(false)}
            className={styles.folderModal}
          >
            <div className={styles.modalContainer}>
              <div className={styles.errorTitle}>{t('reports.error')}</div>
              <div className={styles.errorMessage}>
                {t('reports.insufficientData')}
              </div>
              <div className={styles.buttonContainer}>
                <MaterialButton
                  variant="outlined"
                  soft
                  onClick={() => setErrorModal(false)}
                >
                  {t('general.cancel')}
                </MaterialButton>
              </div>
            </div>
          </Modal>
          <ProductPasteModal
            open={showProductModal}
            handleClose={() => setProductModal(false)}
            returnProducts={(validatedProducts) =>
              addProducts(validatedProducts)
            }
          />
          <ProductPasteModal
            open={showCustomClassDBModal && !customClassDBLocal}
            cloned_custom_class_db_rich={custom_class_db_rich}
            handleClose={() => setCustomClassDBModal(false)}
            returnProducts={(validatedProducts, name) =>
              setCustomClassDB(validatedProducts, name)
            }
            nameSet
          />
          <Modal
            open={showCustomClassDBModal && !!customClassDBLocal}
            onClose={() => setErrorModal(false)}
            className={styles.folderModal}
          >
            <div className={styles.popUpModalContainer}>
              <div className={styles.popUpModalContentTop}>
                <IconButton
                  aria-label="close"
                  className={styles.closeButton}
                  onClick={() => setCustomClassDBModal(false)}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className={styles.popUpModalContent}>
                <DataTable
                  columns={columns}
                  data={customClassDBLocal}
                  striped
                  pagination
                  paginationIconFirstPage={<RDTFirstPage />}
                  paginationIconLastPage={<RDTLastPage />}
                  paginationIconPrevious={<RDTPrevPage />}
                  paginationIconNext={<RTDNextPage />}
                  paginationPerPage={20}
                />
              </div>
              <MaterialButton
                variant="outlined"
                soft
                onClick={() => setCustomClassDB(null, '')}
              >
                Remove Custom Class DB
              </MaterialButton>
            </div>
          </Modal>

          <Grid container spacing={3}>
            <Grid item xs={8}>
              {!showWarning ? (
                <Input
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  value={searchString}
                  onChange={(event) => handleChange(event.target.value)}
                  placeholder={t('panel.searchProduct')}
                  className={styles.searchBar}
                  fullWidth
                />
              ) : (
                <h5 className={styles.limitOne}>
                  {t('reports.productLimitWarning')}
                </h5>
              )}
            </Grid>
            <Grid item xs={2}>
              <MaterialButton
                variant="outlined"
                onClick={() => setProductModal(true)}
              >
                {t('reports.pasteProductList')}
              </MaterialButton>
            </Grid>
            <Grid item xs={2}>
              <MaterialButton
                variant="outlined"
                onClick={() => setCustomClassDBModal(true)}
              >
                Custom Class DB
              </MaterialButton>
            </Grid>
          </Grid>

          {customClassDBCategoryWarn().length > 0 && (
            <div style={{ color: 'orange' }}>
              Please be advised, the following categories are included in your
              custom class db, but not selected for this report:{' '}
              {customClassDBCategoryWarn().join(', ')}
            </div>
          )}

          {searchString.length > 0 && showSearch && isSuperAdmin && (
            <AllProductSearch
              query={searchString}
              hideSearch={() => setShowSearch(false)}
              first={20}
              onClick={addProduct}
              selectMultiple={multiSelectProduct}
            />
          )}
          {searchString.length > 0 &&
            showSearch &&
            !isSuperAdmin &&
            isPartnerAdmin && (
              <PartnerAdminProductSearch
                query={searchString}
                hideSearch={() => setShowSearch(false)}
                first={20}
                onClick={addProduct}
              />
            )}
        </div>
      )}

      <div className={styles.sectionContainer}>
        <span className={styles.sectionHeader}>
          {t('reports.competitiveSet')}
        </span>
      </div>

      <div className={styles.competitiveProductTable}>
        <div>
          {/* style={!products.length ? { height: '100px' } : undefined} */}
          <div className={styles.tag}>
            <table style={{ border: 0 }}>
              <tr>
                <th
                  style={{ paddingLeft: 5, verticalAlign: 'top' }}
                  className={styles.colName}
                >
                  Name
                </th>
                <th
                  style={{ verticalAlign: 'top' }}
                  className={styles.colRename}
                >
                  Alternate Name
                </th>
                <th
                  style={{ verticalAlign: 'top' }}
                  className={styles.colRename}
                >
                  Special Interest?
                </th>
              </tr>
              {uniqBy(newProducts, 'name').map((product) => (
                <CompetitiveProduct
                  product={product}
                  deleteProduct={deleteProduct}
                  displayMode={displayMode}
                  handleNameChange={handleNameChange}
                  handleSpecialInterest={updateProductsOfInterest}
                  specialInterestProducts={specialInterestProducts}
                />
              ))}
            </table>
          </div>
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <span className={styles.sectionHeader}>
          {t('reports.allowedWorkSpaces')}
        </span>
      </div>
      <Grid container>
        {Array.from(
          new Set(competitive_set_rich.map((product) => product.workspace)),
        )
          // TODO FIX PURDY 2023-12-01
          // .sort((a, b) => a.localeCompare(b))
          .map((w) => (
            <Grid item xs={3}>
              <Field
                name={w} // This adds an extra param to the form output for every workspace.
                // Without it, all checkboxes share state.
                component={FieldCheckBox}
                val={client_allowed_workspaces.includes(w)}
                label={w}
                disabled={displayMode}
                onChange={(event) => {
                  change(
                    'client_allowed_workspaces',
                    event.target.checked
                      ? union(client_allowed_workspaces, [w])
                      : client_allowed_workspaces.filter((d) => d != w),
                  );
                }}
              />
            </Grid>
          ))}
      </Grid>
      <div className={styles.sectionContainer}>
        <span className={styles.sectionHeader}>{t('reports.categories')}</span>
      </div>
      {Array.from(
        new Set(
          competitive_set_rich
            .filter((p) => !!p.category)
            .map((product) => product.category?.name),
        ),
      ).map((c) => (
        <Field
          name={c} // This adds an extra param to the form output for every category.
          // Without it, all checkboxes share state.
          component={FieldCheckBox}
          val={competitive_set_folders.includes(c)}
          label={c}
          disabled={displayMode}
          onChange={(event) => {
            change(
              'competitive_set_folders',
              event.target.checked
                ? union(competitive_set_folders, [c])
                : competitive_set_folders.filter((d) => d != c),
            );
          }}
        />
      ))}
    </div>
  );
};

export default MarketSurvey;
