import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    tableRow?: Palette['primary'];
    banner?: Palette['primary'];
    lowError?: Palette['primary'];
    midError?: Palette['primary'];
    highError?: Palette['primary'];
  }
  interface PaletteOptions {
    tableRow?: PaletteOptions['primary'];
    banner?: PaletteOptions['primary'];
    lowError?: PaletteOptions['primary'];
    midError?: PaletteOptions['primary'];
    highError?: PaletteOptions['primary'];
  }
}

export const COLORS = {
  MARINE: '#022950',
  MARINE_FADED: 'rgba(2, 41, 80, 0.4)',
  AQUA_MARINE: '#3ad6cc',
  WHITE: '#ffffff',
  WHITE_FADED: 'rgba(255, 255, 255, 0.4)',
  RICE_WHITE: '#f5f5f5',
  PALE_GREY: '#f4f6f9',
  MID_GREY: '#9e9e9e',
  CORAL_PINK: '#e46e6e',
  SAFFRON: '#eac435',
};

export const GenerateMuiTheme = (branding: any) => {
  const baseTheme = createTheme({
    typography: {
      caption: {
        fontSize: '55%',
      },
    },
    palette: {
      primary: {
        main: COLORS.MARINE,
        light: COLORS.MARINE_FADED,
      },
      secondary: {
        main: branding?.action_button_color ?? COLORS.AQUA_MARINE,
      },
      error: {
        main: COLORS.CORAL_PINK,
      },
      info: {
        main: COLORS.MID_GREY,
      },
      tableRow: {
        main: COLORS.PALE_GREY,
      },
      banner: {
        main: branding?.banner_base_color ?? COLORS.MARINE,
      },
      lowError: {
        main: COLORS.AQUA_MARINE,
      },
      midError: {
        main: COLORS.SAFFRON,
      },
      highError: {
        main: COLORS.CORAL_PINK,
      },
    },
  });

  // theme can be composed in layers
  // https://stackoverflow.com/a/75024119/5383348
  return baseTheme;
};
