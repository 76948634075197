import * as React from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';

import styles from './CreateCategory.module.css';
import FieldTextInput from '../../components/FieldTextInput';
import MaterialButton from '../../components/MaterialButton';
import AllProductCategoriesQuery from '../../graphql/queries/AllProductCategoriesQuery';

interface Props {
  submitting: boolean;
  pristine: boolean;
  invalid: boolean;
  change: (field: string, value: any) => void;
  handleSubmit?: MouseEvent | any;
}

const CreateCategory: React.FC<Props> = (props) => {
  const { handleSubmit, submitting, pristine, invalid } = props;
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(AllProductCategoriesQuery, {
    variables: {
      condition: {
        producerId: 25,
      },
    },
  });

  const [similarCategories, setSimilarCategories] = React.useState<
    Array<string>
  >([]);
  const isSimilar = (categoryA: string, categoryB: string) =>
    categoryA &&
    categoryB &&
    (categoryA.toLowerCase().includes(categoryB.toLowerCase()) ||
      categoryB.toLowerCase().includes(categoryA.toLowerCase()));

  return (
    <div>
      <div className={styles.sectionContainer}>
        <Field
          name="name"
          component={FieldTextInput}
          fullWidth
          label={t('product.productCategory')}
          required
          onChange={(e) =>
            setSimilarCategories(
              data.productCategories.nodes
                .map((d) => d.name)
                .filter((d) => isSimilar(e.target.value, d)),
            )
          }
        />
      </div>

      <div className={styles.sectionContainer}>
        {similarCategories.length > 0
          ? 'Similar categories found: ' +
            similarCategories.slice(0, 5).join(', ')
          : 'No similar categories found'}
      </div>

      <div className={styles.buttonContainer}>
        <MaterialButton
          variant="outlined"
          disabled={pristine || invalid || submitting}
          onClick={handleSubmit}
          soft
          teal
        >
          Submit
        </MaterialButton>
      </div>
    </div>
  );
};

export default CreateCategory;
