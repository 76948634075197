import { useState } from 'react';

import { CheckCircleRounded, ErrorRounded } from '@mui/icons-material';
import { InputLabel, Input } from '@mui/material';
import { useTranslation } from 'react-i18next';

import MaterialButton from '../../components/MaterialButton';

const ResetPasswordContainer = () => {
  const [email, setEmail] = useState<string>('');
  const [response, setResponse] = useState<{ ok: boolean }>(null);
  const [emailError, setEmailError] = useState('');
  const { t } = useTranslation();
  return (
    <div>
      <div style={{ paddingBottom: '10px' }}>
        <InputLabel>{t('users.email')}</InputLabel>
        <Input
          name="email"
          fullWidth
          onChange={(event) => setEmail(event.target.value)}
        />
      </div>

      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <MaterialButton
          variant="outlined"
          disabled={!email}
          onClick={() => {
            setEmailError('');
            setResponse(null);
            fetch('/iam/requestPasswordResetEmail', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email }),
            })
              .then(async (result) => {
                const resetPasswordResponse = await result.text();
                if (resetPasswordResponse == 'Email not found!') {
                  setEmailError('The Email provided does not exist');
                  return setResponse({ ok: false });
                }
                setResponse(result);
              })
              .catch((error) => setResponse(error));
          }}
          soft
          teal
        >
          Submit
        </MaterialButton>
        {!!response && (
          <div
            style={{
              color: response.ok ? 'green' : 'red',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {response.ok ? (
              <CheckCircleRounded fontSize="small" htmlColor="green" />
            ) : (
              <ErrorRounded fontSize="small" htmlColor="red" />
            )}
            &nbsp;
            {response.ok
              ? 'Reset email sent'
              : emailError ?? 'An error occurred'}
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordContainer;
